import * as React from "react"

const LineToYandexEda = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={79}
		height={27}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M79.001 26C40.565 26 47.098 1 0 1"
			stroke="#7F96E4"
			strokeWidth={2}
			strokeDasharray="2 7"
		/>
	</svg>
)

export default LineToYandexEda
