import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import DeliveryBackdropIcon from "./assets/DeliveryBackdropIcon"
import DeliveryClubIcon from "./assets/DeliveryClubIcon"
import LineToDeliveryClub from "./assets/LineToDeliveryClub"
import LineToMobile from "./assets/LineToMobile"
import LineToShop from "./assets/LineToShop"
import LineToYandexEda from "./assets/LineToYandexEda"
import MobileIcon from "./assets/MobileIcon"
import SiteIcon from "./assets/SiteIcon"
import YandexEdaIcon from "./assets/YandexEdaIcon"
import backdropIconsStyles from "./backdropIconsStyles.module.scss"
import mediaContentStyles from "./mediaContentStyles.module.scss"

export default {
	features: [
		{
			desktopTitle: "Принимай заказы\nиз всех источников",
			tabletTitle: "Принимай заказы из всех источников",
			mobileTitle: "Принимай \nзаказы \nиз всех \nисточников",
			desktopContent:
				"Принимай заказы со своего сайта, от агрегаторов доставки и через мобильное приложение в одном интерфейсе. Ни один заказ не потеряется и не опоздает.",
			mediaContent: (
				<>
					<StaticImage
						className={mediaContentStyles.deliveryImage}
						src="./assets/terminal-delivery-feature.png"
						breakpoints={[300, 510, 808, 1616, 817, 1634]}
						sizes={
							"(max-width: 500px) 300px, (max-width: 600px) 510px, (max-width: 1319px) 808px, (min-width: 1320px) 817px"
						}
						alt={"автоматизация фабрики кухни"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>

					<div className={mediaContentStyles.deliveryTilesContainer}>
						<div className={mediaContentStyles.deliveryClubTile}>
							<DeliveryClubIcon
								className={mediaContentStyles.deliveryClubTile__icon}
							/>
						</div>

						<div className={mediaContentStyles.yandexEdaTile}>
							<YandexEdaIcon
								className={mediaContentStyles.yandexEdaTile__icon}
							/>
						</div>
						{/* TODO: Когда появится ссылка, заменить <a> на AdaptiveLink */}
						<a
							className={mediaContentStyles.siteTile}
							target={"_blank"}
							// TODO: link
							href={"https://www.google.com/"}
						>
							<SiteIcon className={mediaContentStyles.siteTile__icon} />
						</a>
						{/* TODO: Когда появится ссылка, заменить <a> на AdaptiveLink */}
						<a
							className={mediaContentStyles.mobileTile}
							target={"_blank"}
							// TODO: link
							href={"https://www.google.com/"}
						>
							<MobileIcon className={mediaContentStyles.mobileTile__icon} />
						</a>
					</div>

					<LineToDeliveryClub
						className={mediaContentStyles.lineToDeliveryClub}
					/>

					<LineToYandexEda className={mediaContentStyles.lineToYandexEda} />

					<LineToShop className={mediaContentStyles.lineToShop} />

					<LineToMobile className={mediaContentStyles.lineToMobile} />
				</>
			),
			detailLink: pagesLinks.terminal,
			backdropContent: (
				<>
					<div className={backdropIconsStyles.backdrop} />
					<DeliveryBackdropIcon
						className={backdropIconsStyles.deliveryBackdropIconLeft}
					/>

					<DeliveryBackdropIcon
						className={backdropIconsStyles.deliveryBackdropIconRight}
					/>
				</>
			),
		},
	] as Array<{
		// Заголовки могут быть для разных раскладок
		desktopTitle: string
		tabletTitle?: string
		mobileTitle?: string

		// Текстовый контент может быть для разных
		desktopContent: string
		tabletContent?: string
		mobileContent?: string

		detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

		mediaContent: JSX.Element
		backdropContent: JSX.Element
	}>,
}
