import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks } from "../../common/links"

import optimizeStyles from "../common/optimize-feature/styles.module.scss";
import implementationStyles from "../common/implementation-feature/styles.module.scss";

export default [
	{
		title: "Комплексная автоматизация Dark Kitchen",
		description: (
			<>
				<p>
					Оптимизируй расходы на оборудование для дарк китчен: не нужно тратить
					время на поиск и настройку, в Quick Resto есть готовый комплект
					оборудования для старта бизнеса.
				</p>
				<p>От 42 000 ₽</p>
				<ul>
					<li>
						Тикет-Принтер Атол RP 326 (Ethernet).
					</li>
					<li>
						Apple iPad Mini 2, б/у.
					</li>
					<li>
						Стойка для iPad Mini .<br />
						Маршрутизатор Keenetic 4G KN-1211.
					</li>
				</ul>
			</>
		),
		img: (
			<StaticImage
				className={optimizeStyles.image}
				src="../common/optimize-feature/optimize-feature.png"
				alt="кассовое оборудование для dark kitchen"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: optimizeStyles.feature,
		theme: "white",
		actionText: "Подробнее",
		actionLink: pagesLinks.shop.index.href,
	},
	{
		title: "Внедрение автоматизации Dark kitchen под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",
		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
