import * as React from "react"
import { SVGProps } from "react"

const DeliveryBackdropIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 351 532"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M175.376 0C78.52 0 0 78.518 0 175.376c0 96.859 175.376 356.591 175.376 356.591s175.377-259.734 175.377-356.591C350.753 78.52 272.23 0 175.376 0Zm0 264.967c-52.249 0-94.607-42.355-94.607-94.604s42.358-94.605 94.607-94.605 94.605 42.356 94.605 94.605-42.356 94.604-94.605 94.604Z"
			fill="#E5EEFF"
		/>
	</svg>
)

export default DeliveryBackdropIcon
