import * as React from "react"
import { SVGProps } from "react"

const ChevronRight = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 6 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.25 1.5 4.75 5l-3.5 3.5"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default ChevronRight
