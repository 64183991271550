import * as React from "react"

const LineToMobile = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={77}
		height={81}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M78 1C39.082 1 60.052 81.512 1 79.993"
			stroke="#7F96E4"
			strokeWidth={2}
			strokeDasharray="2 7.5"
		/>
	</svg>
)

export default LineToMobile
