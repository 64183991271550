import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import mediaContent from "./mediaContent.module.scss";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import { Locales } from "../../../localization/types";


export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Заказы",
					desktopTitle: "Программа автоматизации для Dark kitchen в облаке",
					desktopContent: (
						<p>
							Заказы с&nbsp;сайта, от&nbsp;агрегаторов и&nbsp;с&nbsp;мобильного
							приложения поступают на&nbsp;кассовый терминал Quick
							Resto&nbsp;&mdash; заказ будет обработан максимально быстро. Отчёт
							по&nbsp;доставке даст информацию, какой источник самый эффективный
							и&nbsp;приносит наибольшую прибыль. Если клиент звонит
							по&nbsp;телефону, добавить его данные к&nbsp;заказу можно в&nbsp;пару
							кликов&nbsp;&mdash; создать новую карточку клиента или привязать уже
							существующую по&nbsp;номеру телефона.{" "}
						</p>
					),
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/kitchen-modes-1.png"
							alt="оборудование для дарк китчен"
							breakpoints={[440, 740, 920, 568]}
							sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 568px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Доставка",
					desktopTitle: "Доставляй заказы вовремя",
					desktopContent: (
						<p>
							Выбирай, кто будет доставлять заказы&nbsp;&mdash; курьеры Яндекс
							Доставки, Delivery Club или свои сотрудники. Если собственных курьеров
							не&nbsp;хватает, можно вызвать курьера службы доставки прямо
							с&nbsp;кассового терминала. Чек для курьера содержит всю необходимую
							информацию о&nbsp;заказе: адрес, контактные данные клиента, позиции
							и&nbsp;общую сумму заказа.{" "}
						</p>
					),
					detailLink: pagesLinks.delivery,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/kitchen-modes-2.png"
							alt="запуск dark kitchen"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 440,
					tabName: "CRM",
					desktopTitle: "Увеличивай количество заказов",
					desktopContent: (
						<p>
							Получай больше заказов с&nbsp;помощью сайта своего заведения.
							Подключение доставки с&nbsp;расчетом стоимости по&nbsp;зонам, опция
							самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
							Выстраивай программу лояльности на&nbsp;основе информации
							о&nbsp;клиентах из&nbsp;CRM-системы Quick Resto: их&nbsp;заказах,
							предпочтениях, частоте трат и&nbsp;днях рождения.
						</p>
					),
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/kitchen-modes-3.png"
							alt="crm система для dark kitchen"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}
	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Заказы",
					desktopTitle: "Программа автоматизации для Dark kitchen в облаке",
					desktopContent: (
						<p>
							Заказы с&nbsp;сайта, от&nbsp;агрегаторов и&nbsp;с&nbsp;мобильного
							приложения поступают на&nbsp;кассовый терминал Quick
							Resto&nbsp;&mdash; заказ будет обработан максимально быстро. Отчёт
							по&nbsp;доставке даст информацию, какой источник самый эффективный
							и&nbsp;приносит наибольшую прибыль. Если клиент звонит
							по&nbsp;телефону, добавить его данные к&nbsp;заказу можно в&nbsp;пару
							кликов&nbsp;&mdash; создать новую карточку клиента или привязать уже
							существующую по&nbsp;номеру телефона.{" "}
						</p>
					),
					detailLink: pagesLinks.terminal,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/kitchen-modes-1.png"
							alt="оборудование для дарк китчен"
							breakpoints={[440, 740, 920, 568]}
							sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 568px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Доставка",
					desktopTitle: "Доставляй заказы вовремя",
					desktopContent: (
						<p>
							Выбирай, кто будет доставлять заказы&nbsp;&mdash; курьеры Яндекс
							Доставки, Delivery Club или свои сотрудники. Если собственных курьеров
							не&nbsp;хватает, можно вызвать курьера службы доставки прямо
							с&nbsp;кассового терминала. Чек для курьера содержит всю необходимую
							информацию о&nbsp;заказе: адрес, контактные данные клиента, позиции
							и&nbsp;общую сумму заказа.{" "}
						</p>
					),
					detailLink: pagesLinks.delivery,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/kitchen-modes-2.png"
							alt="запуск dark kitchen"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 440,
					tabName: "CRM",
					desktopTitle: "Увеличивай количество заказов",
					desktopContent: (
						<p>
							Получай больше заказов с&nbsp;помощью сайта своего заведения.
							Подключение доставки с&nbsp;расчетом стоимости по&nbsp;зонам, опция
							самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
							Выстраивай программу лояльности на&nbsp;основе информации
							о&nbsp;клиентах из&nbsp;CRM-системы Quick Resto: их&nbsp;заказах,
							предпочтениях, частоте трат и&nbsp;днях рождения.
						</p>
					),
					detailLink: pagesLinks.marketing,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/kitchen-modes-3.png"
							alt="crm система для dark kitchen"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 510,
				tabName: "Заказы",
				desktopTitle: "Программа автоматизации для Dark kitchen в облаке",
				desktopContent: (
					<p>
						Заказы с&nbsp;сайта, от&nbsp;агрегаторов и&nbsp;с&nbsp;мобильного
						приложения поступают на&nbsp;кассовый терминал Quick
						Resto&nbsp;&mdash; заказ будет обработан максимально быстро. Отчёт
						по&nbsp;доставке даст информацию, какой источник самый эффективный
						и&nbsp;приносит наибольшую прибыль. Если клиент звонит
						по&nbsp;телефону, добавить его данные к&nbsp;заказу можно в&nbsp;пару
						кликов&nbsp;&mdash; создать новую карточку клиента или привязать уже
						существующую по&nbsp;номеру телефона.{" "}
					</p>
				),
				detailLink: pagesLinks.terminal,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/kitchen-modes-1.png"
						alt="оборудование для дарк китчен"
						breakpoints={[440, 740, 920, 568]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 568px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 490,
				tabName: "Доставка",
				desktopTitle: "Доставляй заказы вовремя",
				desktopContent: (
					<p>
						Выбирай, кто будет доставлять заказы&nbsp;&mdash; курьеры Яндекс
						Доставки, Delivery Club или свои сотрудники. Если собственных курьеров
						не&nbsp;хватает, можно вызвать курьера службы доставки прямо
						с&nbsp;кассового терминала. Чек для курьера содержит всю необходимую
						информацию о&nbsp;заказе: адрес, контактные данные клиента, позиции
						и&nbsp;общую сумму заказа.{" "}
					</p>
				),
				detailLink: pagesLinks.delivery,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/kitchen-modes-2.png"
						alt="запуск dark kitchen"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 440,
				tabName: "CRM",
				desktopTitle: "Увеличивай количество заказов",
				desktopContent: (
					<p>
						Получай больше заказов с&nbsp;помощью сайта своего заведения.
						Подключение доставки с&nbsp;расчетом стоимости по&nbsp;зонам, опция
						самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						Выстраивай программу лояльности на&nbsp;основе информации
						о&nbsp;клиентах из&nbsp;CRM-системы Quick Resto: их&nbsp;заказах,
						предпочтениях, частоте трат и&nbsp;днях рождения.
					</p>
				),
				detailLink: pagesLinks.marketing,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/kitchen-modes-3.png"
						alt="crm система для dark kitchen"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	};
};
