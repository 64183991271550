import * as React from "react"

const LineToShop = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={78}
		height={55}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M77.5 1.13C39.623-2.043 57.916 54 0 54"
			stroke="#7F96E4"
			strokeWidth={2}
			strokeDasharray="2 7"
		/>
	</svg>
)

export default LineToShop
