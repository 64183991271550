import * as React from "react"

const LineToDeliveryClub = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={91}
		height={175}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0 1h18.712c5.29 0 17.29 9 17.29 21.5s.217 85.896 0 122.357c0 22.074 27.16 29.143 39.5 29.143h15.096"
			stroke="#7F96E4"
			strokeWidth={2}
			strokeDasharray="2 7"
		/>
	</svg>
)

export default LineToDeliveryClub
