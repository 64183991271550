import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Программа автоматизации Dark kitchen&nbsp;</span>
	),
	price: "от 2490 ₽/мес",
	subTitle: (
		<p className={styles.description}>
			Комплексное решение для автоматизации фабрики-кухни и запуска сервиса по
			доставке еды от 2490 ₽/мес.
			<br />
			Dark Kitchen обладает рядом преимуществ в сравнении с традиционными офлайн
			фуд-проектами: сравнительно небольшие вложения на старте, небольшая
			арендная плата и невысокий уровень операционных затрат. Quick Resto —
			готовое решение для быстрого запуска dark kitchen и эффективного
			управления проектом.
		</p>
	),
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/dark-kitchen-presentation.png"
			alt=" автоматизация dark kitchen"
			breakpoints={[440, 740, 920, 560]}
			sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 560px"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, \nс удобным интерфейсом. И две \nнедели бесплатно.",
}
