import React, { useState } from "react"
import classNames from "classnames"
import { showElemFullyInScrollContainer } from "../../../utils/showElemFullyInScrollContainer"

import styles from "./styles.module.scss"

import AdaptiveWrapText from "../../../components/_V2/AdaptiveWrapText"

import ChevronRight from "../../../assets/_V2/svg/ChevronRight"
import AdaptiveLink from "../../../components/_V2/AdaptiveLink"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Feature {
	tabName?: string

	// Заголовки могут быть для разных раскладок
	desktopTitle: string
	tabletTitle?: string
	mobileTitle?: string

	// Текстовый контент может быть для разных
	desktopContent: string
	tabletContent?: string
	mobileContent?: string

	detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

	mediaContent: JSX.Element
	backdropContent: JSX.Element
}

type ColorTheme =
	| "blueSky"
	| "greenWarm"
	| "greenLight"
	| "fuchsia"
	| "blueSkyLight"
	| "purple"

/*type Size = (
    'md' | 'lg'
)*/

interface Props {
	className?: string
	data: Feature[]
	theme: ColorTheme
	transformOnAdaptive?: boolean
	mediaContainerOverflowVisible?: boolean
	featureContainer?: string
}

export default function FullScreenFeatures(props: Props) {

	const localizationContext = useLocalizationContext()
	const features = props.data
	const isSingle = features.length === 1
	const isTransformOnAdaptive =
		props.transformOnAdaptive !== undefined ? props.transformOnAdaptive : true
	const isMediaContainerOverflowVisible =
		props.mediaContainerOverflowVisible || false

	const [selectedFeature, setSelectedFeature] = useState(features[0])

	const setFeaturePosition = (featureData, evt) => {
		showElemFullyInScrollContainer(
			evt.currentTarget,
			evt.currentTarget.parentNode,
			true
		)

		setSelectedFeature(featureData)
	}

	const renderTabList = (): JSX.Element => (
		<ul className={classNames(styles.tabsList, styles.tabsListFeature)}>
			{features.map(feature => (
				<li
					key={feature.tabName}
					className={classNames(styles.tab, {
						[styles.__selected]: feature === selectedFeature,
					})}
					onClick={e => {
						setFeaturePosition(feature, e)
					}}
				>
					{feature.tabName}
				</li>
			))}
		</ul>
	)

	return (
		<section className={classNames(styles.section, props.className)}>
			<div
				className={classNames(styles.features, styles[props.theme], {
					[styles.isSingle]: isSingle,
					[styles.transformOnAdaptive]: isTransformOnAdaptive,
				})}
			>
				{selectedFeature.backdropContent}

				<div className={classNames(styles.featureContainer, props.featureContainer)}>
					{features.length > 1 && renderTabList()}

					{features.map(item => (
						<div
							key={"feature" + item.tabName}
							className={styles.featureItemContainer}
						>
							<div
								className={classNames(
									styles.featureDescriptiveContentContainer,
									{
										[styles.__selected]:
											item.tabName === selectedFeature.tabName,
									}
								)}
							>
								{/* <h2 className={styles.featureTitle}>
                                    <AdaptiveWrapText
                                        desktop={item.desktopTitle}
                                        tablet={item.tabletTitle}
                                        mobile={item.mobileTitle}
                                    />
                                </h2> */}

								<AdaptiveWrapText
									desktop={item.desktopTitle}
									tablet={item.tabletTitle}
									mobile={item.mobileTitle}
									titleClasses={classNames(styles.featureTitle,
										{[styles.featureTitle__kz]: localizationContext.locale === 'kz-KZ'})}
								>
									<h2>{item.desktopTitle}</h2>
								</AdaptiveWrapText>

								{/* <AdaptiveWrapText
                                    className={styles.featureContent}
                                    desktop={item.desktopContent}
                                    tablet={item.tabletContent}
                                    mobile={item.mobileContent}
                                /> */}

								<AdaptiveWrapText
									className={styles.featureContent}
									desktop={item.desktopContent}
									tablet={item.tabletContent}
									mobile={item.mobileContent}
								>
									<span>{item.desktopContent}</span>
								</AdaptiveWrapText>
							</div>
						</div>
					))}

					{features.map(item => (
						<div
							key={"feature" + item.tabName}
							className={classNames(
								styles.featuresMediaContainer,
								{
									[styles.__selected]: item.tabName === selectedFeature.tabName,
								},
								{
									[styles.featuresMediaContainer_overflowVisible]: isMediaContainerOverflowVisible,
								}
							)}
						>
							{item.mediaContent}
						</div>
					))}
				</div>

				{selectedFeature.detailLink && (
					<div className={styles.featureFooter}>
						<AdaptiveLink
							className={styles.featureDetailLink}
							href={selectedFeature.detailLink.href}
							useGatsbyLink={selectedFeature.detailLink.useGatsbyLink}
						>
							<>
								{localizationContext.locale === 'kz-KZ' ? "Толығырақ" : "Подробнее"}
								<ChevronRight />
							</>
						</AdaptiveLink>
					</div>
				)}
			</div>
		</section>
	)
}
