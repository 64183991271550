import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import classNames from "classnames"

export default {
	features: [
		{
			desktopContentWidth: 524,
			tabName: "Универсальное решение",
			desktopTitle: "Электронное меню \nдля Dark kitchen",
			mobileTitle: "Электронное \nменю \nдля Dark \nkitchen",
			desktopContent: (
				<p>
					Электронное меню полностью заменяет бумажную версию. Размести ссылку
					на&nbsp;QR-меню в&nbsp;соцсетях и&nbsp;на&nbsp;онлайн-картах города.
					Не нужно откладывать запуск dark kitchen и ждать, пока меню выйдет из печати.
					А&nbsp;с&nbsp;акциями можно экспериментировать хоть
					каждый день. Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики:
					добавь цвета своего бренда, логотип и&nbsp;фотостиль.
				</p>
			),
			detailLink: pagesLinks.wlShop.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/digital-feature-1.png"
					alt=" электронное меню для дарк китчен"
					breakpoints={[440, 740, 920, 640]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 640px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 435,
			tabName: "Приложение",
			desktopTitle: "Работай с гостями напрямую через приложение",
			desktopContent: (
				<p>
					Запусти брендированное приложение&nbsp;&mdash; быстро, бесплатно, без
					найма разработчиков. Гости смогут пользоваться бонусной программой,
					делать заказы на&nbsp;доставку. Оставаться с&nbsp;ними на&nbsp;связи
					просто&nbsp;&mdash; об&nbsp;акциях и&nbsp;предложениях можно
					оповестить через push.
				</p>
			),
			detailLink: pagesLinks.indexWL,
			mediaContent: (
				<>
					<StaticImage
						className={mediaContent.image2}
						src="./assets/digital-feature-2.png"
						alt="мобильное приложение для заказа еды"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={classNames(
							mediaContent.image2,
							mediaContent.image2Tablet
						)}
						src="./assets/digital-feature-2-tablet.png"
						alt="мобильное приложение для заказа еды"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
		},
		{
			desktopContentWidth: 520,
			tabName: "Карта лояльности",
			desktopTitle: "Подключай клиентов онлайн",
			desktopContent: (
				<p>
					Предложи клиентам оформить электронные карту лояльности в&nbsp;Apple
					Wallet или Google Pay. Системный подход к&nbsp;бонусным программам
					и&nbsp;CRM доступен на&nbsp;любом тарифе Quick Resto&nbsp;&mdash;
					данные гостя и&nbsp;информация о&nbsp;заказах автоматически
					сохраняются в&nbsp;CRM. Ссылку для оформления карты можно разместить
					в&nbsp;соцсетях или на&nbsp;сайте.
				</p>
			),
			detailLink: pagesLinks.marketing,
			mediaContent: (
				<>
					<StaticImage
						className={mediaContent.image3}
						src="./assets/digital-feature-3.png"
						alt="crm система для dark kitchen"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={classNames(
							mediaContent.image3,
							mediaContent.image3Tablet
						)}
						src="./assets/digital-feature-3-tablet.png"
						alt="crm система для dark kitchen"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
		},
	],
}
