import React from "react"

import data from "./data"
import FullScreenFeatures from "../../../components/_V2/FullScreenFeatures"

interface Props {
	className?: string
}

export default function TerminalFeatures(props: Props) {
	return (
		<FullScreenFeatures
			className={props.className}
			data={data.features}
			theme={"blueSkyLight"}
			transformOnAdaptive={true}
		/>
	)
}
