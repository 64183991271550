import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Сайт",
			desktopTitle: "Собственный сайт \nдля доставки",
			tabletTitle: "Собственный сайт для доставки",
			mobileTitle: "Собственный сайт \nдля доставки",
			desktopContent: (
				<div className={mediaContent.smallDescription}>
					Создай приложение и&nbsp;сайт своего заведения за&nbsp;5&nbsp;минут.
					Удобный интерфейс, доставка, актуальное меню и&nbsp;бонусная
					программа. Выгодно для бизнеса и&nbsp;гостя. Блюда выгрузятся
					из&nbsp;Quick Resto автоматически.
				</div>
			),
			detailLink: pagesLinks.indexWL,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/kitchen-calculation-1.png"
					alt="сайт для дарк китчен"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Техкарты",
			desktopTitle: "Четкие стандарты приготовления блюд",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Заказы мгновенно попадают на&nbsp;кухню и&nbsp;распределяются между
					поварами. Для каждого блюда&nbsp;&mdash; своя техкарта со&nbsp;списком
					ингредиентов и&nbsp;рецептом. Отслеживай время приготовления блюд
					и&nbsp;работу поваров.
				</p>
			),
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/kitchen-calculation-2.png"
					alt="расчет себестоимости блюд"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
