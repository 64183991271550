import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 520,
			tabName: "Аналитика",
			desktopTitle: "Онлайн-система управления Dark kitchen",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Принимай решения на основе данных. <br />
					Облачный бэк-офис Quick Resto — единая система для управления
					продажами, складом, кухней, персоналом, финансами и поставщиками. Есть
					как наглядные виджеты для оперативного среза, так и детальные отчёты:
					поможем принять правильное решение на любой стадии бизнеса и повысить
					продажи.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/dark-kitchen-accounting-1.png"
					alt="программа учета продуктов в dark kitchen"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 520,
			tabName: "Отчеты",
			desktopTitle: "Отчёты: когда нужно копнуть глубже",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Сводные таблицы по типам оплат, кассирам, блюдам и другим параметрам.
					Если запрос нестандартный, поможет конструктор отчётов. Посмотреть
					чеки клиента, оплаченные банковской картой, по акции «1+1», ещё и за
					прошлый месяц? Не проблема!
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/dark-kitchen-accounting-2.png"
					alt="отчеты по продажам"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 520,
			tabName: "Склад",
			desktopTitle: "Всё по полочкам",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Возьми под контроль весь цикл товарооборота: от&nbsp;закупок и&nbsp;прихода
					до&nbsp;списания, актов разбора и&nbsp;переработки. Программа для автоматизации
					фабрики-кухни в&nbsp;режиме реального времени показывает остатки, недостачи и&nbsp;излишки.
					И&nbsp;подскажет, когда нужно пополнить запасы.
				</p>
			),
			detailLink: pagesLinks.warehouse,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/dark-kitchen-accounting-3.png"
					alt="складской учет для дарк китчен"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
