import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../../layout/default'
import AutomationMenu from '../../components/_V2/AutomationMenu'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'

import TerminalFeatures from '../../pagesSections/avtomatizaciya-dark-kitchen/TerminalFeatures'
import CalculationFeatures
  from '../../pagesSections/avtomatizaciya-dark-kitchen/CalculationFeatures'
import ModesFeatures from '../../pagesSections/avtomatizaciya-dark-kitchen/ModesFeatures'
import DigitalFeatures from '../../pagesSections/avtomatizaciya-dark-kitchen/DigitalFeatures'
import AccountingFeatures from '../../pagesSections/avtomatizaciya-dark-kitchen/AccountingFeatures'
import AccountingFeaturesIntegrations from "../../pagesSections/intergations/AccountingFeatures"
import ProductsFeatures from "../../pagesSections/intergations/ProductsFeatures"
import AdditionalServices from "../../pagesSections/index/AdditionalServices"

import ProductPresentation
  from '../../pagesSections/avtomatizaciya-dark-kitchen/ProductPresentation'

import Presentation
  from '../../pagesSections/avtomatizaciya-dark-kitchen/ProductPresentation/assets/dark-kitchen-presentation.png'
import CtaForm from '../../pagesSections/index/CtaForm'

import featuresData from '../../pages-data/_V2/automatization/kitchen/features'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'

export default function PageDarkKitchen() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кофейни, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: 'Программа автоматизации Dark kitchen  ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/dark-kitchen/',
    },
    {
      property: 'og:title',
      content: 'Автоматизация Dark kitchen — комплексная программа автоматизации фабрики кухни | Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Программа автоматизации Dark kitchen  ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title="Автоматизация Dark kitchen — комплексная программа автоматизации фабрики кухни | Quick Resto"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      footerClassName={styles.automation__footer}
      schemaType="Product"
      schemaName={metaTags.find((tag) => tag.property === 'og:title').content}
      schemaDescription={metaTags.find((tag) => tag.property === 'og:description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
					  pageStyles.pageSection,
					  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <TerminalFeatures className={pageStyles.pageSection} />

      <CalculationFeatures className={pageStyles.pageSection} />

      <ModesFeatures className={pageStyles.pageSection} />

      <DigitalFeatures className={pageStyles.pageSection} />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />

      <AccountingFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index < 1)}
        className={pageStyles.pageSection}
      />

      <AccountingFeaturesIntegrations className={pageStyles.pageSection} theme="white" />

      <ProductsFeatures className={pageStyles.pageSection} theme="white" />

      <AdditionalServices className={styles.additionalServices} theme="white" />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index >= 1)}
        className={pageStyles.pageSection}
      />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
    </DefaultLayout>
  )
}
